<template>
  <div class="poster-search-panel poster-search-panel__root">
    <!-- SEARCH MODE SELECTOR -->
    <template v-if="searchConfiguration.modes.length > 1">
      <poster-search-mode-selector
        :items="searchConfiguration.modes"
        :value="selectedSearchMode"
        class="poster-search-panel__mode-selector"
        @change="changeSearchMode"
      >
      </poster-search-mode-selector>
    </template>

    <pui-flex class="poster-search-panel__content-wrapper" flex="1">
      <!-- SEARCH FORM + RESULTS -->
      <template v-if="selectedSearchMode != null">
        <pui-flex
          :class="{
            'poster-search-panel__content': true,
            sharedWidth: !isMultiStepSearch && isMultipleResultSearch,
          }"
        >
          <!-- Search inputs (Search form) -->
          <poster-search-form
            v-if="hasInput"
            ref="searchForm"
            :searchDefinition="searchDefinition"
            :searchValues="searchValues"
            :loadOptionsFunction="getPossibleValues"
            :disabled="isSearching"
            @attributeValueInput="onAttributeValueInput"
            @attributeValueChange="onAttributeValueChange"
            @attributeKeyup="onAttributeKeyup"
          >
            <template #actions>
              <pui-button-list>
                <pui-button
                  v-if="showResetSearchForm"
                  :outline="true"
                  class="reset-search-form"
                  variant="secondary"
                  @click="resetSearchForm(true)"
                >
                  {{ $t('poster.search.form.reset') }}
                </pui-button>
                <pui-button
                  v-if="searchDefinition.showSearchButton !== false"
                  :disabled="!isSearchFormValid || isSearching"
                  class="search-button"
                  picto="mdi-magnify"
                  variant="primary"
                  @click="search(null, true)"
                >
                  {{ $t('poster.search.form.trigger_search') }}
                </pui-button>
              </pui-button-list>
            </template>
          </poster-search-form>

          <!-- SEARCH RESULTS -->
          <!-- Disable transition for multi step: results should always take full width -->
          <transition :name="isMultiStepSearch ? '' : 'expand-width'">
            <div
              v-if="isMultiStepSearch || showSearchResultsPanel"
              :class="{
                'poster-search-panel__search-results': true,
                isMultipleResultSearch,
              }"
            >
              <pui-common-spinner
                v-show="isSearching"
                :message="$t('poster.search.in_progress')"
                position="fixed"
              />
              <div class="search-result-header">
                <h3 v-if="hasInput" class="header-title">
                  {{ $t('poster.search.results.title') }}
                </h3>
                <pui-flex class="header-right" alignItems="center">
                  <span v-if="isMultipleResultSearch" class="result-count">
                    {{
                      searchResult.totalNumberOfItems !== -1
                        ? $t('poster.search.results.total_and_selection_count', {
                            total: searchResult.totalNumberOfItems,
                            selection: selectedSearchResults.length,
                          })
                        : $t('poster.search.results.selection_count', {
                            selection: selectedSearchResults.length,
                          })
                    }}
                  </span>
                  <span v-else class="result-count">
                    {{
                      searchResult.totalNumberOfItems !== -1
                        ? $t('poster.search.results.total_count', {
                            total: searchResult.totalNumberOfItems,
                            selection: selectedSearchResults.length,
                          })
                        : ''
                    }}
                  </span>
                  <pui-button
                    v-if="!isMultiStepSearch && isMultipleResultSearch"
                    :flat="true"
                    :outline="false"
                    class="btn-close-search-results"
                    picto="mdi-close"
                    variant="secondary"
                    @click="closeSearchResultsPanel"
                  />
                </pui-flex>
              </div>
              <h2 v-show="searchResult.items.length === 0 && !isSearching" class="search-nodata">
                <span class="mdi mdi-table" />{{
                  isSearchPerformed
                    ? noResultMessage
                    : $t('poster.search.results.no_results.search_not_executed')
                }}
              </h2>
              <pui-table
                v-show="searchResult.items.length > 0"
                ref="resultsTable"
                :items="searchResult.items"
                :columns="resultsColumns"
                :allowSelection="true"
                :selectionMode="isMultipleResultSearch ? 'multi' : 'single'"
                :columnSorts="currentSorts"
                :tableEditors="PosterTableEditors"
                :tableRenderers="PosterTableRenderers"
                :getItemId="getSearchResultId"
                class="results-table"
                @selectionChanged="updateSelectedSearchResults"
                @cellDblClick="searchResultsDblClick"
                @columnSort="onColumnSort"
              />
              <pui-flex class="search-result-footer" direction="column">
                <pui-flex
                  v-if="!!displayedSearchMode && !!displayedSearchMode.search.paging"
                  class="search-result-footer-pagination-info"
                >
                  <pui-pagination
                    v-if="searchResult.pageIndex !== -1"
                    :total="searchResult.totalNumberOfItems"
                    :pageSize="searchResult.pageSize"
                    :value="searchResult.pageIndex"
                    :pageMode="paginationPageMode"
                    :atIndeterminateEnd="atIndeterminateEnd"
                    class="search-result-footer-pagination"
                    @change="onPaginationChange"
                  >
                  </pui-pagination>
                  <div class="search-result-footer-page-size">
                    <span v-if="showStaticPageSize">
                      {{
                        $t('poster.search.results.pageSizes.static', {
                          count: searchResult.pageSize,
                        })
                      }}
                    </span>
                    <i18n
                      v-else
                      path="poster.search.results.pageSizes.editable"
                      class="editable"
                      tag="div"
                    >
                      <template #select
                        ><pui-common-select
                          :options="displayedSearchMode.search.paging.pageSizes"
                          :value="selectedPageSize"
                          :multiple="false"
                          :taggable="false"
                          :allowGrouping="false"
                          :clearable="false"
                          class="items-per-page-selector"
                          @select="selectedItemsPerPageChanged"
                        />
                      </template>
                    </i18n>
                  </div>
                </pui-flex>
                <pui-flex justifyContent="end">
                  <pui-button-list class="search-result-footer-button-list">
                    <template>
                      <slot
                        :selectedSearchResultCount="selectedSearchResults.length"
                        :validateSelectedResults="validateSelectedResults"
                        name="searchActions"
                      >
                        <pui-button
                          v-if="isMultipleResultSearch"
                          :outline="false"
                          :disabled="!selectedSearchResults.length"
                          class="search-result-footer-add-cart-btn"
                          variant="primary"
                          @click="validateSelectedResults"
                        >
                          {{ $t('poster.search.results.add_selection_to_cart') }}
                        </pui-button></slot
                      >
                    </template>
                  </pui-button-list>
                </pui-flex>
              </pui-flex>
            </div>
          </transition>
        </pui-flex>
      </template>

      <!-- CART SLOT -->
      <slot :showSearchResultsPanel="showSearchResultsPanel" name="searchSummaryPanel" />
    </pui-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

import helpers from '../../../../core/helpers';
import { ignoreNavigationDuplicatedErrorHandler } from '../../../../core/router';
import services from '../../../../core/services';
import { AttributeTypes } from '../../../common/constants';
import { areAllAttributesValid } from '../../../common/helpers/attributeValidators';
import {
  getAttributeDefaultValue,
  getFormUrlQuery,
  iterateForm,
  queryToFormValues,
} from '../../../common/helpers/formsHelper';
import { EXECUTE_SEARCH_AUTO } from '../../constants/batchCreation';
import { POSTER_SESSION_SETTINGS } from '../../constants/sessionSettings';
import { getPosterService } from '../../services';
import { PosterTableEditors } from '../table/editors';
import { PosterTableRenderers } from '../table/renderers';

/**
 * Initial search result state
 */
const INITIAL_SEARCH_RESULT = {
  items: [],
  pageIndex: -1,
  pageSize: 20,
  totalNumberOfItems: -1,
  totalNumberOfPages: -1,
};

export default {
  name: 'PosterSearchPanel',
  props: {
    searchFunction: {
      type: Function,
    },
    attributeMap: {
      type: Map,
      required: false,
    },
    templateId: {
      type: String,
      required: false,
    },
    searchConfiguration: {
      type: Object,
      required: true,
    },
    /**
     * Shows exclusively the search results or the selection cart.
     * If disabled: can show both at the same time.
     */
    isMultiStepSearch: {
      type: Boolean,
      default: false,
    },
    /**
     * Shows a button to reset the search form
     */
    showResetSearchForm: {
      type: Boolean,
      default: false,
    },
    /**
     * Function that provides search mode form state.
     * Only used when `isMultiStepSearch`.
     */
    searchModeFormStateProvider: {
      type: Function,
      default: null,
    },
    /**
     * The default search mode to select on mount
     * (if in the available search modes)
     */
    defaultSearchModeId: {
      type: String,
      default: null,
    },
    /**
     * Url query keys to use for prefilling the search
     * Set to null if prefilling should be disabled.
     * Only used when `isMultiStepSearch`.
     */
    urlQueryConfig: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      searchValues: {},
      pageIndex: 1,
      searchResult: INITIAL_SEARCH_RESULT,
      selectedPageSize: undefined,
      selectedSearchResults: [],
      resultsColumns: [],
      isSearchPerformed: false,
      isSearching: false,
      showSearchResultsPanel: true,
      selectedSearchMode: null,
      displayedSearchMode: null,
      PosterTableEditors,
      PosterTableRenderers,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    searchDefinition() {
      return this.selectedSearchMode ? this.selectedSearchMode.search : null;
    },
    /**
     * Flag indicated if search is in single or multiple mode.
     */
    isMultipleResultSearch() {
      return this.searchConfiguration && this.searchConfiguration.multiSelect === true;
    },
    /**
     * No result message.
     */
    noResultMessage() {
      let message;
      if (this.searchDefinition && this.searchDefinition.noDataMessage) {
        message = this.piivoTranslateLabel(this.searchDefinition.noDataMessage);
      }

      return message || this.$t('poster.search.results.no_results.no_results');
    },
    /**
     * Returns true if search form has at least one input, false otherwise.
     */
    hasInput() {
      if (
        this.searchDefinition &&
        this.searchDefinition.searchForm &&
        this.searchDefinition.searchForm.attributeGroups &&
        this.searchDefinition.searchForm.attributeGroups.length > 0
      ) {
        for (const group of this.searchDefinition.searchForm.attributeGroups) {
          if (group.attributes != null && group.attributes.length > 0) {
            return true;
          }
        }
      }

      return false;
    },
    /**
     * Check search form validity (True if required fields filled or no input).
     * @returns {Boolean} The form validity
     */
    isSearchFormValid() {
      if (this.hasInput) {
        // Check values of form groups required inputs
        const checkResult = areAllAttributesValid(
          this.searchValues,
          { attributeGroups: this.searchDefinition.searchForm.attributeGroups },
          {
            validateInvisibleItems: false,
            breakOnFirstError: true,
          }
        );
        return Object.keys(checkResult.errors).length === 0;
      }

      return true;
    },
    /**
     * @returns {boolean} if we should show the page size message with a static amount
     * instead of a configurable select
     */
    showStaticPageSize() {
      return (
        !this.displayedSearchMode.search.paging ||
        !this.displayedSearchMode.search.paging.pageSizes ||
        this.displayedSearchMode.search.paging.pageSizes.length <= 1
      );
    },
    /**
     * @returns {string} the pagination page mode according to the results
     */
    paginationPageMode() {
      return this.searchResult.totalNumberOfPages === -1 ? 'indeterminate' : 'determinate';
    },
    /**
     * @returns {boolean} if we are at the last page / passed the last page in indeterminate mode
     */
    atIndeterminateEnd() {
      return (
        this.paginationPageMode === 'indeterminate' &&
        (!this.searchResult.items.length ||
          this.searchResult.items.length < this.searchResult.pageSize)
      );
    },
    /**
     * @returns {string} the search mode key for the persistent sorts
     */
    sortSettingKey() {
      if (!this.selectedSearchMode) {
        return 'default';
      }

      return `SEARCH_SORTS_${this.selectedSearchMode.alias}`;
    },
    currentSorts: {
      get() {
        if (this.sortSettingKey === 'default') {
          return [];
        }

        return (
          services
            .getService('sessionSettings')
            .getInCategory(POSTER_SESSION_SETTINGS, this.sortSettingKey) || []
        );
      },
      set(sorts) {
        if (this.sortSettingKey === 'default') {
          return;
        }

        services
          .getService('sessionSettings')
          .setInCategory(POSTER_SESSION_SETTINGS, this.sortSettingKey, sorts);
      },
    },
  },
  watch: {
    /**
     * Watches the route to update search mode and or values
     */
    $route() {
      // Don't apply search mode / values if nothing in query otherwise we will "erase" current values
      // This is required when cleaning the url
      if (Object.keys(this.$route.query).length <= 0) {
        return;
      }

      const searchModeAlias = this.urlQueryConfig
        ? getFormUrlQuery(this.$route.query, this.urlQueryConfig.searchMode)
        : null;
      const urlSearchMode =
        this.urlQueryConfig && this.searchConfiguration
          ? this.searchConfiguration.modes.find((mode) => mode.alias === searchModeAlias)
          : null;

      // Select first mode even if nothing in the url so we can apply url attribute values
      const selectedMode = urlSearchMode || this.searchConfiguration.modes[0];
      if (selectedMode) {
        this.changeSearchMode(selectedMode);
      }
    },
  },
  methods: {
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
    piivoTranslateLabel(value) {
      return Vue.filter('piivoTranslateLabel')(value);
    },
    /**
     * @param {object} item - search result item
     * @returns {number} unique id for the item
     */
    getSearchResultId(item) {
      return item._id;
    },
    /**
     * Applies the url query as form values
     */
    applyUrlFormValues() {
      const urlValues = queryToFormValues(
        this.$route.query,
        this.selectedSearchMode.search.searchForm.attributeGroups.flatMap(
          (group) => group.attributes
        )
      );
      for (const alias in urlValues) {
        this.$set(this.searchValues, alias, urlValues[alias]);
      }

      // Clear the url query so that the component is the only source of state
      // Otherwise the user could change search mode, come back to the original search mode
      // and the url would be used again
      // eslint-disable-next-line promise/prefer-await-to-then
      this.$router.replace({ query: null }).catch(ignoreNavigationDuplicatedErrorHandler);
    },
    /**
     * Initialize search.
     *
     * @param {boolean} resetForm - if the form values should be reset
     * (only used when !isMultiStepSearch)
     */
    initSearch(resetForm) {
      // Init search context
      this.isSearching = false;
      this.isSearchPerformed = false;

      if (this.isMultiStepSearch) {
        let state = null;
        if (this.selectedSearchMode.search.preserveFormValues && this.searchModeFormStateProvider) {
          state = this.searchModeFormStateProvider(this.selectedSearchMode.itemId);
        }

        // Restore values if they exist, or reset to default attr values
        if (state && state.values) {
          this.searchValues = JSON.parse(JSON.stringify(state.values));
        } else {
          this.resetSearchForm(false);
        }

        this.applyUrlFormValues();
      } else if (!this.isMultiStepSearch) {
        if (resetForm) {
          // Reset result table selection
          this.searchResult = INITIAL_SEARCH_RESULT;
          this.resetSearchForm(false);
        }
      }

      if (this.$refs.resultsTable != null) {
        this.$refs.resultsTable.resetSelection();
      }

      // When not in multiple search mode, we only want to show the cart at start
      // so we invert the initial display value of the results panel
      this.showSearchResultsPanel = !this.isMultipleResultSearch;

      // Focus first attribute
      this.$nextTick(() => {
        if (this.hasInput && this.$refs.searchForm) {
          this.$refs.searchForm.focusFirstAttribute();
        }
      });
    },
    /**
     * Resets the search form values with the default attribute values
     *
     * @param {boolean} clearSavedValues - if the saved search values should be cleared
     */
    resetSearchForm(clearSavedValues = false) {
      if (this.hasInput === false) {
        // No inputs
        this.searchValues = null;
      } else {
        this.searchValues = {};
        // New search => init default values (user + form default values)
        const userValues =
          this.user.extendedProperties != null
            ? JSON.parse(JSON.stringify(this.user.extendedProperties))
            : {};
        const attributeCb = (attribute) => {
          const defaultValue =
            Object.hasOwnProperty.call(userValues, attribute.alias) &&
            userValues[attribute.alias] != null
              ? userValues[attribute.alias]
              : getAttributeDefaultValue(attribute);
          if (defaultValue != null) {
            this.onAttributeValueChange(attribute, defaultValue, false);
          }
        };
        iterateForm(this.searchDefinition.searchForm, attributeCb);
      }

      if (clearSavedValues) {
        this.$emit('clearSearchValues', this.selectedSearchMode);
      }
    },
    /**
     * Start loading (show spinner).
     */
    startLoading() {
      this.isSearching = true;
    },
    /**
     * Stop loading (hide spinner).
     */
    stopLoading() {
      this.isSearching = false;
    },
    /**
     * Close search results panel.
     */
    closeSearchResultsPanel() {
      this.showSearchResultsPanel = false;
      this.pageIndex = 1;
    },
    /**
     * Update attributes value.
     * @param {object} attribute - changed attribute
     * @param {*} newValue - New attribute value
     */
    onAttributeValueInput(attribute, newValue) {
      this.$set(this.searchValues, attribute.alias, newValue);
      this.pageIndex = 1;
    },
    /**
     * @param {object} attribute - changed attribute
     * @param {*} newValue - New attribute value
     * @param {Boolean} doSearch - Flag indicates if search has to be done after value changed
     */
    onAttributeValueChange(attribute, newValue, doSearch = true) {
      this.onAttributeValueInput(attribute, newValue);

      if (this.$refs.searchForm) {
        this.$refs.searchForm.doUpdateOn(attribute);
      }

      // Launch search only for certain types
      if (
        doSearch &&
        (attribute.type === AttributeTypes.LINKS || attribute.type === AttributeTypes.BOOLEAN)
      ) {
        this.search(attribute.alias, true);
      }
    },
    /**
     * Triggered when an attribute field emits keyuo
     *
     * @param {object} attribute - the attribute object
     */
    onAttributeKeyup(attribute) {
      this.search(attribute.alias, true);
    },
    /**
     * Updates the selected page
     * @param {number} pageIndex - fake index, starts at 1
     */
    onPaginationChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.search(null, false);
    },
    /**
     * Updates the selected page size
     * @param {number|number[]} pageSize - the selected option
     */
    selectedItemsPerPageChanged(pageSize) {
      pageSize = Array.isArray(pageSize) ? (pageSize.length > 0 ? pageSize[0] : null) : pageSize;

      this.selectedPageSize = pageSize;
      this.search(null, true);
    },
    /**
     * @param {object} attribute - the attribute whose options to retrieve
     * @param {object} parameters - parameters for the load
     * @param {object} parameters.context - additional context to load the values
     * @returns {Promise<object[]>} options for the attribute
     */
    getPossibleValues(attribute, { context }) {
      return getPosterService('values').getPossibleValues(
        attribute,
        context,
        this.templateId,
        (bodyParameters) =>
          getPosterService('values').addAttributeValuesBodyParameters(
            bodyParameters,
            this.searchValues,
            this.searchDefinition && this.searchDefinition.searchForm
              ? this.searchDefinition.searchForm.attributeGroups
              : []
          )
      );
    },
    /**
     * Launch search.
     * @param {String} triggerAlias (optional) : no trigger = auto/programmatic launch or attribute alias trigger
     * @param {boolean} resetPagination - if pagination should be reset prior to the search
     */
    async search(triggerAlias = null, resetPagination) {
      this.searchResult = INITIAL_SEARCH_RESULT;

      // Check trigger to continue search (no trigger or trigger alias in triggers list)
      if (
        triggerAlias != null &&
        (this.searchDefinition.triggeredBy == null ||
          this.searchDefinition.triggeredBy.includes(triggerAlias) === false)
      ) {
        return;
      }
      if (this.isSearching) {
        return;
      }

      // Verify search form and launch search
      if (!this.isSearchFormValid) {
        return;
      }

      // While searching, we disable the attributes, so the focus will be lost.
      // Store the focused attribute so we can restore it after search
      if (this.$refs.searchForm) {
        this.$refs.searchForm.saveFocusedAttribute();
      }

      this.startLoading();

      const isDifferentSearchMode =
        !this.displayedSearchMode ||
        this.displayedSearchMode.itemId !== this.selectedSearchMode.itemId;
      if (isDifferentSearchMode) {
        // Reset the selected page size, if the search we are about to execute is a
        // different mode than the one currentyly displayed
        if (
          this.selectedSearchMode.search.paging &&
          this.selectedSearchMode.search.paging.pageSizes &&
          this.selectedSearchMode.search.paging.pageSizes.length
        ) {
          this.selectedPageSize = this.selectedSearchMode.search.paging.pageSizes[0];
        } else {
          this.selectedPageSize = undefined;
        }
      }
      if (resetPagination) {
        this.pageIndex = 1;
      }

      this.showSearchResultsPanel = true;
      this.isSearchPerformed = true;

      // Compute columns
      helpers
        .getHelper('tableHelper')
        .computeTableColumns(this.searchDefinition.columns, this.attributeMap);
      this.resultsColumns = this.searchDefinition.columns;

      const paging = this.selectedSearchMode.search.paging
        ? {
            index: this.pageIndex,
            size: this.selectedPageSize,
          }
        : undefined;

      try {
        // Call search function
        const results = await this.searchFunction(this.searchValues, paging, this.currentSorts);
        // Reset result table selection
        if (this.$refs.resultsTable != null) {
          this.$refs.resultsTable.resetSelection();
        }

        this.displayedSearchMode = this.selectedSearchMode;
        this.searchResult = results;
      } catch (err) {
        services.getService('alerts').alertError(this.$t('poster.search.error'));
      }

      this.stopLoading();

      // Restore focus of focused attribute before search
      this.$nextTick(() => {
        if (this.$refs.searchForm) {
          this.$refs.searchForm.restoreFocusedAttribute();
        }
      });
    },
    /**
     * Update selected search results.
     * @param {Array} selectedSearchResults : New search result selection
     */
    updateSelectedSearchResults(selectedSearchResults) {
      this.$emit(
        'selectedSearchedItemsChanged',
        selectedSearchResults,
        this.isMultipleResultSearch
      );
      this.selectedSearchResults = selectedSearchResults;
    },
    /**
     * Double click event on search results (Only for single mode).
     */
    searchResultsDblClick() {
      // Emit dblClick event only in single mode
      if (!this.isMultipleResultSearch) {
        this.validateSelectedResults();
      }
    },
    /**
     * Column sort event.
     * @param {[sortKey: string, sortDirection: string][]} sorts - sorts entries
     */
    onColumnSort(sorts) {
      this.pageIndex = 1;
      this.currentSorts = sorts;
      this.search(null, true);
    },
    /**
     * Validates selected results.
     */
    validateSelectedResults() {
      this.$emit('searchResultsValidated', this.selectedSearchResults);

      // Reinitializes results panel after validation
      if (this.isMultipleResultSearch) {
        this.showSearchResultsPanel = false;
      }
    },
    /**
     * Change selected search mode.
     * @param {Object} searchMode : New selected search mode
     */
    changeSearchMode(searchMode) {
      const isDifferentSearchMode =
        !this.selectedSearchMode || this.selectedSearchMode.itemId !== searchMode.itemId;
      this.selectedSearchMode = searchMode;
      this.$emit('changeSearchMode', this.selectedSearchMode);

      // Grab search before url is reset
      const executeSearch = this.urlQueryConfig?.executeSearch
        ? this.$route.query[this.urlQueryConfig.executeSearch]
        : null;

      this.initSearch(isDifferentSearchMode);

      if (executeSearch === EXECUTE_SEARCH_AUTO || this.selectedSearchMode.search.triggerOnSelect) {
        this.search(null, true);
      }
    },
  },
  /**
   * Launch on cell change for a signage item in the summary table.
   * @param {Object} item : signage item modified
   * @param {String} property : property name
   * @param {Object} value : new value
   */
  onSummaryCellValueChanged(item, property, value) {
    item[property] = value;
  },
  mounted() {
    // Init selectedSearchMode
    if (
      this.searchConfiguration != null &&
      this.searchConfiguration.modes != null &&
      this.searchConfiguration.modes.length > 0
    ) {
      // Try to restore search mode
      let defaultSearchMode;
      if (this.defaultSearchModeId) {
        defaultSearchMode = this.searchConfiguration.modes.find(
          (mode) => mode.itemId === this.defaultSearchModeId
        );
      }
      // Fallback to first mode
      if (!defaultSearchMode) {
        defaultSearchMode = this.searchConfiguration.modes[0];
      }
      this.changeSearchMode(defaultSearchMode);
    }
  },
};
</script>
